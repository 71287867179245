.rich-text-area{
    width: 91%;
    height: 300px;
    margin-top: 8px;
}
.rich-text-area-modal {
    width: 100%;
    height: 300px;
    margin-top: 8px;
}
.rich-text-area-specification{
    width: 100%;
    height: 300px;
    margin-top: 8px;
}

